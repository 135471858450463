.banner {
    /* content: url(../banner_with_logo.png);
    max-width: 65%;
    display: inline;
    padding-right: 1rem; */
}

a {
    text-decoration: none;
    color: black;
}

.banner_contactInfo {
    max-width: 30%;
    display: inline-block;
    padding-left: 1.2rem;
    border-left: 1px dotted red;

}

.bannerContainer {
    background-color: azure;
    padding: 0.25rem;
    border-bottom: 2px dotted black;
    padding-bottom: 0.7rem;
}

.banner_contactInfo>ul>li>img {
    vertical-align: middle;
    margin-right: 5px;

}

.banner_img {
    width: 64%;
}

.banner_contactInfo>ul>li {
    list-style: none;
    border-radius: 10px;
    background: #80808052;
    font-weight: lighter;
    color: #0b0b08;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    margin: 0rem 0.2rem 0.2rem 0rem;
}


.menuContaner>a>li {
    font-weight: bold;
}


.top_banner_container {
    padding-bottom: 1rem;
}

@media screen and (max-width: 1080px) {

    .banner,
    .banner_contactInfo {
        display: block;
        max-width: 100%;
    }

    .banner_img {
        max-width: 100%;
        width: 100%
    }

    .banner {
        padding-bottom: 0.2rem;
        border-bottom: 1px dashed red;
    }

    .banner_contactInfo {
        max-width: 100%;
        display: flex;
        border: none;
        padding-top: 0.5rem;
        justify-content: center;
        padding: 0;
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .banner_contactInfo>ul>li {
        display: inline-block;
    }



    .menuContaner {
        justify-content: center;
    }

    .menuContaner>a>li {
        margin-left: 5px;
        margin-right: 5px;
    }

    .footerContainer>div {
        flex-basis: 49%;
        margin-bottom: 3px;
    }

}

@media screen and (max-width: 680px) {

    .menuContaner>a>li {
        margin: 0px;
        /* border-right: 0.5px solid grey;
        border-left: 0.5px solid grey; */
    }

    .footerContainer>div {
        flex-basis: 100%;
        margin-bottom: 3px;
    }



    .banner_contactInfo>ul>li {
        font-size: 0.8rem;
        padding: 0.1rem 0.2rem 0.1rem 0.2rem;
        margin: 0rem 0.2rem 0.2rem 0rem;
    }

    .bannerContainer {
        padding-bottom: 0.2rem;
    }

    .banner_contactInfo>ul {
        width: 100%;
        padding: 0;
    }
}


@media screen and (max-width: 480px) {

    .menuContaner>a>li {
        margin: 0px;
        font-size: 0.7rem;
        font-weight: bold;
    }

}