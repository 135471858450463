.Bank_detail {
    border: 2px solid black;
    padding: 1.25rem;
    border-radius: 20px;
    margin-bottom: 20px;
}

.Bank_detail pre {
    display: inline-block;
    font-size: 1.3rem;
}

.Bank_detail h2 {
    text-decoration-line: underline;
    text-decoration-color: red;
    /* color: red; */
}
