.bod_element {
    width: 250px;
    border: 2px solid;
    padding: 1rem;
    height: 350px;
    border-radius: 10px;

    text-align: center;


    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.bod_mem_class_container {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);

    padding-top: 1rem;
    padding-bottom: 1rem;
}


.bod_element>.bod_img {
    height: 250px;
    display: flex;
    justify-content: center;
    justify-items: center;
}

.bod_element>.bod_img>img {
    max-width: 100%;
    max-height: 100%;

}

@media screen and (max-width: 1080px) {
    .bod_member_container>div {
        display: grid;

    }
}