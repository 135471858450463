* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


body {
  overflow-x: hidden;
}

.container {
  padding: 40px 0.3rem 0.5rem 0.3rem;
}

.routes_container {
  padding: 1.25rem;
  padding-top: 1rem;
  text-align: justify;
  background-image: linear-gradient(140deg, #eeddd9b2 0%, #e2eca9b6 75%);
}


.txt_align_center {
  text-align: center
}

.contatc_form_container {
  padding: 1rem;
  background: #d3d3d3c4;
  border-radius: 10px;
  border: 1px solid darkgray;

}

.bod_member_container>div {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.sticky_top_H_NAME {
  background-color: #c0c0c080;
  position: sticky;
  top: 35px;
  width: 100%;
  border: 2px dotted white;
  z-index: 100;
  display: inline-block;
  text-align: initial;
}

.horizontal_line {
  height: 5px;
  border: none;
  background: rgba(30, 124, 143, 1);
  background: radial-gradient(circle, rgba(255, 29, 0, 1) 3%, rgba(30, 124, 143, 1) 15%, rgba(170, 35, 35, 1) 35%, rgba(30, 124, 143, 1) 60%, rgba(143, 30, 30, 1) 90%, rgba(30, 143, 134, 1) 93%);
}

.underline {
  text-decoration-line: underline;
}

.alert_bank {
  color: grey;
  font-size: 2rem;
  padding: 1rem;
  border: 2px dotted purple;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  overflow: hidden;
}

@media screen and (max-width: 680px) {


  .services_items_container {
    display: grid;
    justify-content: center;

  }

}