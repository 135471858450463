.menuContaner {
    height: 35px;
    background: linear-gradient(-45deg, #0f733b, #294aa5);
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;

    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;


    background: linear-gradient(130deg, #0f733b, #294aa5, #0f733b);
    background-size: 700% 700%;
    animation: gradient 5s linear infinite;
}

.menuContaner>a>li {
    list-style: none;
    margin-right: 8px;
    margin-left: 8px;
    cursor: pointer;
    padding: 0.2rem;
    color: white;
}

.menuContaner>a {
    border: 1px solid #dbc481b3;
    border-radius: 7px;
    margin-right: 10px;
}

.menuContaner {
    padding-right: 2.5rem;
}

a {
    text-decoration: none;
}







@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 50% 100%;
    }

    100% {
        background-position: 0% 50%;
    }
}





@media screen and (max-width: 1080px) {

    .banner,
    .banner_contactInfo {
        display: block;
        max-width: 100%;
    }

    .banner {
        padding-bottom: 0.2rem;
        border-bottom: 1px dashed red;
    }

    .banner_contactInfo {
        max-width: 100%;
        display: flex;
        border: none;
        padding-top: 0.5rem;
        justify-content: center;
    }

    .banner_contactInfo>ul>li {
        display: inline-block;
    }



    .menuContaner {
        justify-content: center;
        padding-right: 0;
    }

    .menuContaner>a>li {
        margin-left: 5px;
        margin-right: 5px;
    }

    .footerContainer>div {
        flex-basis: 49%;
        margin-bottom: 3px;
    }

}

@media screen and (max-width: 580px) {

    .menuContaner>a>li {
        margin: 0px;
    }

    .footerContainer>div {
        flex-basis: 100%;
        margin-bottom: 3px;
    }

}