a {
    text-decoration: none;
}




.footerContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0.5rem;
    background-color: grey;
    color: white;
}

.footerContainer>div {
    display: inline-block;
    width: 100%;

    flex-basis: 24%;
    border-left: 1px dotted white;
    padding: 1rem;
}

.footer_impLinks>a {
    display: block;
    color: white;
    padding: 0.5rem;
}

.footer_links_Sum>a>li {
    list-style: none;
    color: white;
    padding: 0.5rem;
}

h3 {
    color: #0f1310;
    border-bottom: 2px solid white;
}





@media screen and (max-width: 1080px) {

    .banner,
    .banner_contactInfo {
        display: block;
        max-width: 100%;
    }

    .banner {
        padding-bottom: 0.2rem;
        border-bottom: 1px dashed red;
    }

    .banner_contactInfo {
        max-width: 100%;
        display: flex;
        border: none;
        padding-top: 0.5rem;
        justify-content: center;
    }

    .banner_contactInfo>ul>li {
        display: inline-block;
    }



    .menuContaner {
        justify-content: center;
    }

    .menuContaner>a>li {
        margin-left: 5px;
        margin-right: 5px;
    }

    .footerContainer>div {
        flex-basis: 49%;
        margin-bottom: 3px;
    }

}

@media screen and (max-width: 580px) {

    .menuContaner>a>li {
        margin: 0px;

    }

    .footerContainer>div {
        flex-basis: 100%;
        margin-bottom: 3px;
    }

}