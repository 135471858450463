.card__container {
  font-weight: 100;
  display: inline-block;
  width: 350px;
  position: relative;
  top: 0;
  height: 200px;
  overflow: scroll;

  padding: 1rem;
  margin-bottom: 20px;
  border-radius: 20px;
  background: linear-gradient(to right, #eee, #eef);
  
  margin-right: 20px;
}

.card__container:hover {
  box-shadow: grey 3px 3px 3px 2px;
}

.styleLocationLabel {
  color: #8294aa;
  font-size: 14px;
  margin: 4px 0;
}

@media screen and (max-width: 580px) {

.card__container {
  font-weight: 100;
font-size:1.3rem;
  width: 100%;

  padding: 1rem;

  margin-right: 0;
}


}
