.notice_ITEM {
    width: 100%;
    overflow: hidden;
    height: 500px;
    display: flex;
    position: relative;
    border-radius: 10px;
}

.notice_text_container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-top-left-radius: 40%;
    border-top-right-radius: 40%;
    text-shadow: -1px -1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff, 1px 1px 0 #ffffff;

    background: #8080806b;
    background: linear-gradient(#8080803a, #8080806b);
    backdrop-filter: blur(1px);
    padding-top: 0.3rem;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 0;
}

.notice_sumarry {
    font-weight: 900;
    font-size: 1.5rem;
    text-align: center;
    border-bottom: 1px dotted black;
}

.notice_description {
    font-size: 1.1rem;
    text-align: center;
}

.notice_date {
    color: rgb(13, 14, 15);
    font-size: 1.1rem;
    border-top: 1px solid white;
    text-align: center;
}

.notice_img {
    width: 100%;
    flex-shrink: 0;
}

.notice_container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    gap: 15px;
}

.notice_container>div {
    flex: 45%;
    flex: 0 45%;
    flex-basis: 45%;
    box-shadow: 0 0 10px 3px #5e5050;
    margin-bottom: 10px;
}






@media screen and (max-width: 1080px) {
    .notice_container>div {
        flex: 100%;
        flex: 0 100%;
        flex-basis: 100%;
        max-width: 700px;
    }

    .notice_text_container {
        border-top-left-radius: 20%;
        border-top-right-radius: 20%;
    }
}