* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


body {
  overflow-x: hidden;
}

.container {
  padding: 40px 0.3rem 0.5rem 0.3rem;
}

.routes_container {
  padding: 1.25rem;
  padding-top: 1rem;
  text-align: justify;
  background-image: linear-gradient(140deg, #eeddd9b2 0%, #e2eca9b6 75%);
}


.txt_align_center {
  text-align: center
}

.contatc_form_container {
  padding: 1rem;
  background: #d3d3d3c4;
  border-radius: 10px;
  border: 1px solid darkgray;

}

.bod_member_container>div {
  display: flex;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}

.sticky_top_H_NAME {
  background-color: #c0c0c080;
  position: -webkit-sticky;
  position: sticky;
  top: 35px;
  width: 100%;
  border: 2px dotted white;
  z-index: 100;
  display: inline-block;
  text-align: initial;
}

.horizontal_line {
  height: 5px;
  border: none;
  background: rgba(30, 124, 143, 1);
  background: radial-gradient(circle, rgba(255, 29, 0, 1) 3%, rgba(30, 124, 143, 1) 15%, rgba(170, 35, 35, 1) 35%, rgba(30, 124, 143, 1) 60%, rgba(143, 30, 30, 1) 90%, rgba(30, 143, 134, 1) 93%);
}

.underline {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.alert_bank {
  color: grey;
  font-size: 2rem;
  padding: 1rem;
  border: 2px dotted purple;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  overflow: hidden;
}

@media screen and (max-width: 680px) {


  .services_items_container {
    display: grid;
    justify-content: center;

  }

}
.menuContaner {
    height: 35px;
    background: linear-gradient(-45deg, #0f733b, #294aa5);
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;

    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;


    background: linear-gradient(130deg, #0f733b, #294aa5, #0f733b);
    background-size: 700% 700%;
    -webkit-animation: gradient 5s linear infinite;
            animation: gradient 5s linear infinite;
}

.menuContaner>a>li {
    list-style: none;
    margin-right: 8px;
    margin-left: 8px;
    cursor: pointer;
    padding: 0.2rem;
    color: white;
}

.menuContaner>a {
    border: 1px solid #dbc481b3;
    border-radius: 7px;
    margin-right: 10px;
}

.menuContaner {
    padding-right: 2.5rem;
}

a {
    text-decoration: none;
}







@-webkit-keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 50% 100%;
    }

    100% {
        background-position: 0% 50%;
    }
}







@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 50% 100%;
    }

    100% {
        background-position: 0% 50%;
    }
}





@media screen and (max-width: 1080px) {

    .banner,
    .banner_contactInfo {
        display: block;
        max-width: 100%;
    }

    .banner {
        padding-bottom: 0.2rem;
        border-bottom: 1px dashed red;
    }

    .banner_contactInfo {
        max-width: 100%;
        display: flex;
        border: none;
        padding-top: 0.5rem;
        justify-content: center;
    }

    .banner_contactInfo>ul>li {
        display: inline-block;
    }



    .menuContaner {
        justify-content: center;
        padding-right: 0;
    }

    .menuContaner>a>li {
        margin-left: 5px;
        margin-right: 5px;
    }

    .footerContainer>div {
        flex-basis: 49%;
        margin-bottom: 3px;
    }

}

@media screen and (max-width: 580px) {

    .menuContaner>a>li {
        margin: 0px;
    }

    .footerContainer>div {
        flex-basis: 100%;
        margin-bottom: 3px;
    }

}
.card__container {
  font-weight: 100;
  display: inline-block;
  width: 350px;
  position: relative;
  top: 0;
  height: 200px;
  overflow: scroll;

  padding: 1rem;
  margin-bottom: 20px;
  border-radius: 20px;
  background: linear-gradient(to right, #eee, #eef);
  
  margin-right: 20px;
}

.card__container:hover {
  box-shadow: grey 3px 3px 3px 2px;
}

.styleLocationLabel {
  color: #8294aa;
  font-size: 14px;
  margin: 4px 0;
}

@media screen and (max-width: 580px) {

.card__container {
  font-weight: 100;
font-size:1.3rem;
  width: 100%;

  padding: 1rem;

  margin-right: 0;
}


}

.bod_element {
    width: 250px;
    border: 2px solid;
    padding: 1rem;
    height: 350px;
    border-radius: 10px;

    text-align: center;


    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.bod_mem_class_container {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);

    padding-top: 1rem;
    padding-bottom: 1rem;
}


.bod_element>.bod_img {
    height: 250px;
    display: flex;
    justify-content: center;
    justify-items: center;
}

.bod_element>.bod_img>img {
    max-width: 100%;
    max-height: 100%;

}

@media screen and (max-width: 1080px) {
    .bod_member_container>div {
        display: grid;

    }
}
.notice_ITEM {
    width: 100%;
    overflow: hidden;
    height: 500px;
    display: flex;
    position: relative;
    border-radius: 10px;
}

.notice_text_container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-top-left-radius: 40%;
    border-top-right-radius: 40%;
    text-shadow: -1px -1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff, 1px 1px 0 #ffffff;

    background: #8080806b;
    background: linear-gradient(#8080803a, #8080806b);
    -webkit-backdrop-filter: blur(1px);
            backdrop-filter: blur(1px);
    padding-top: 0.3rem;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 0;
}

.notice_sumarry {
    font-weight: 900;
    font-size: 1.5rem;
    text-align: center;
    border-bottom: 1px dotted black;
}

.notice_description {
    font-size: 1.1rem;
    text-align: center;
}

.notice_date {
    color: rgb(13, 14, 15);
    font-size: 1.1rem;
    border-top: 1px solid white;
    text-align: center;
}

.notice_img {
    width: 100%;
    flex-shrink: 0;
}

.notice_container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    grid-gap: 15px;
    gap: 15px;
}

.notice_container>div {
    flex: 45% 1;
    flex: 0 1 45%;
    flex-basis: 45%;
    box-shadow: 0 0 10px 3px #5e5050;
    margin-bottom: 10px;
}






@media screen and (max-width: 1080px) {
    .notice_container>div {
        flex: 100% 1;
        flex: 0 1 100%;
        flex-basis: 100%;
        max-width: 700px;
    }

    .notice_text_container {
        border-top-left-radius: 20%;
        border-top-right-radius: 20%;
    }
}
.Bank_detail {
    border: 2px solid black;
    padding: 1.25rem;
    border-radius: 20px;
    margin-bottom: 20px;
}

.Bank_detail pre {
    display: inline-block;
    font-size: 1.3rem;
}

.Bank_detail h2 {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    -webkit-text-decoration-color: red;
            text-decoration-color: red;
    /* color: red; */
}

a {
    text-decoration: none;
}




.footerContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0.5rem;
    background-color: grey;
    color: white;
}

.footerContainer>div {
    display: inline-block;
    width: 100%;

    flex-basis: 24%;
    border-left: 1px dotted white;
    padding: 1rem;
}

.footer_impLinks>a {
    display: block;
    color: white;
    padding: 0.5rem;
}

.footer_links_Sum>a>li {
    list-style: none;
    color: white;
    padding: 0.5rem;
}

h3 {
    color: #0f1310;
    border-bottom: 2px solid white;
}





@media screen and (max-width: 1080px) {

    .banner,
    .banner_contactInfo {
        display: block;
        max-width: 100%;
    }

    .banner {
        padding-bottom: 0.2rem;
        border-bottom: 1px dashed red;
    }

    .banner_contactInfo {
        max-width: 100%;
        display: flex;
        border: none;
        padding-top: 0.5rem;
        justify-content: center;
    }

    .banner_contactInfo>ul>li {
        display: inline-block;
    }



    .menuContaner {
        justify-content: center;
    }

    .menuContaner>a>li {
        margin-left: 5px;
        margin-right: 5px;
    }

    .footerContainer>div {
        flex-basis: 49%;
        margin-bottom: 3px;
    }

}

@media screen and (max-width: 580px) {

    .menuContaner>a>li {
        margin: 0px;

    }

    .footerContainer>div {
        flex-basis: 100%;
        margin-bottom: 3px;
    }

}
.banner {
    /* content: url(../banner_with_logo.png);
    max-width: 65%;
    display: inline;
    padding-right: 1rem; */
}

a {
    text-decoration: none;
    color: black;
}

.banner_contactInfo {
    max-width: 30%;
    display: inline-block;
    padding-left: 1.2rem;
    border-left: 1px dotted red;

}

.bannerContainer {
    background-color: azure;
    padding: 0.25rem;
    border-bottom: 2px dotted black;
    padding-bottom: 0.7rem;
}

.banner_contactInfo>ul>li>img {
    vertical-align: middle;
    margin-right: 5px;

}

.banner_img {
    width: 64%;
}

.banner_contactInfo>ul>li {
    list-style: none;
    border-radius: 10px;
    background: #80808052;
    font-weight: lighter;
    color: #0b0b08;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    margin: 0rem 0.2rem 0.2rem 0rem;
}


.menuContaner>a>li {
    font-weight: bold;
}


.top_banner_container {
    padding-bottom: 1rem;
}

@media screen and (max-width: 1080px) {

    .banner,
    .banner_contactInfo {
        display: block;
        max-width: 100%;
    }

    .banner_img {
        max-width: 100%;
        width: 100%
    }

    .banner {
        padding-bottom: 0.2rem;
        border-bottom: 1px dashed red;
    }

    .banner_contactInfo {
        max-width: 100%;
        display: flex;
        border: none;
        padding-top: 0.5rem;
        justify-content: center;
        padding: 0;
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .banner_contactInfo>ul>li {
        display: inline-block;
    }



    .menuContaner {
        justify-content: center;
    }

    .menuContaner>a>li {
        margin-left: 5px;
        margin-right: 5px;
    }

    .footerContainer>div {
        flex-basis: 49%;
        margin-bottom: 3px;
    }

}

@media screen and (max-width: 680px) {

    .menuContaner>a>li {
        margin: 0px;
        /* border-right: 0.5px solid grey;
        border-left: 0.5px solid grey; */
    }

    .footerContainer>div {
        flex-basis: 100%;
        margin-bottom: 3px;
    }



    .banner_contactInfo>ul>li {
        font-size: 0.8rem;
        padding: 0.1rem 0.2rem 0.1rem 0.2rem;
        margin: 0rem 0.2rem 0.2rem 0rem;
    }

    .bannerContainer {
        padding-bottom: 0.2rem;
    }

    .banner_contactInfo>ul {
        width: 100%;
        padding: 0;
    }
}


@media screen and (max-width: 480px) {

    .menuContaner>a>li {
        margin: 0px;
        font-size: 0.7rem;
        font-weight: bold;
    }

}
